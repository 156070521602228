div.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
}
div.pagination p {
    color: black;
    float: left;
    padding: 5px 15px;
    text-decoration: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
div.pagination p.active {
    background-color: #000099;
    color: white;
    border: 1px solid #000099;
}
div.pagination p:hover:not(.active) {background-color: lightpink;}
div.center {text-align: center;}