.step-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 182px;
  float: left;
}

.step-item:not(:first-child)::before {
  content: '';
  background-color: #cbd5e0; /* Adjust background color as needed */
  position: absolute;
  width: 100%;
  height: 3px;
  right: 39%;
  top: 33%; /* Adjust top position as needed */
  transform: translateY(-50%);
}

.step {
  width: 2.5rem; /* Adjust width as needed */
  height: 2.5rem; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #4a5568; /* Adjust background color as needed */
  border-radius: 9999px;
  font-weight: 600; /* Adjust font weight as needed */
  color: #fff; /* Adjust text color as needed */
}

.active .step {
  background-color: #4299e1; /* Adjust background color for active step */
}

.complete .step {
  background-color: #48bb78; /* Adjust background color for completed step */
}

.complete p {
  color: #fff; /* Adjust text color for completed step content */
}

.complete:not(:first-child)::before,
.active:not(:first-child)::before {
  background-color: #48bb78; /* Adjust background color for active and completed step line */
}
